import React from "react";

function Welcome() {
  return (
    <div>
      <h1>Welcome</h1>
    </div>
  );
}

export default Welcome;
